import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

export default ({
  pageTitle,
  pageDescription,
  pagePath,
  pageOgImage,
  noIndex,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query AppHead {
          wpgraphql {
            appOptions {
              global {
                title
                description
                url
                twitterId
              }
            }
          }
        }
      `}
      render={(data) => {
        const global = data.wpgraphql.appOptions.global
        return (
          <Helmet
            title={pageTitle ? pageTitle + " - " + global.title : global.title}
            htmlAttributes={{ lang: "ja" }}
            bodyAttributes={{
              class: "kitaq-guide",
            }}
          >
            <meta
              property="description"
              content={pageDescription ? pageDescription : global.description}
            />
            <meta
              property="format-detection"
              content="telephone=no, email=no, address=no"
            />
            <meta
              property="og:title"
              content={
                pageTitle ? pageTitle + " - " + global.title : global.title
              }
            />
            <meta
              property="og:description"
              content={pageDescription ? pageDescription : global.description}
            />
            <meta
              property="og:url"
              content={pagePath ? global.url + pagePath : global.url}
            />
            <meta
              property="og:image"
              content={
                pageOgImage ? global.url + pageOgImage : global.url + "/ogp.jpg"
              }
            />
            <meta property="og:site_name" content={global.title} />
            <meta
              property="og:type"
              content={pagePath === "/" ? "website" : "article"}
            />
            <meta name="twitter:card" content="summary_large_image" />
            {global.twitterId ? (
              <meta
                property="twitter:creator"
                content={"@" + global.twitterId}
              />
            ) : (
              ""
            )}
            {noIndex ? (
              <meta name="robots" content="noindex, nofollow"></meta>
            ) : (
              ""
            )}
            <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
            <link rel="icon" href="/favicon.png" />
          </Helmet>
        )
      }}
    />
  )
}
